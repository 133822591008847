import { authHeader  } from '../helpers';
export const userService = {
    login,
    GetUsersTable,
    EditUser,
    DeleteUser,
    GetGroupTable,
    EditGroup,
    DeleteGroup,
    ViewUser,
    ViewGroup,
    GetRidesTable,
    DeleteRide,
    MessageGroup,
    GetGraph,
    GetStatisic,
    GetSettings,
    SetSettings
};
const config= 'http://relaypigeon.com:5050';
function login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })

    };
    return fetch(`${config}/auth-admin/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // console.log(user)
            if (user.token ) {
                localStorage.setItem('user_token', JSON.stringify(user.token));
                localStorage.setItem('LoginStatus', true);
            }
            // if(user.user && user.user.role && user.user.role !== "business"){
                localStorage.setItem('role', user.user.role)
            // }
            return user;
        });
}

function GetUsersTable(data) {
    const requestOptions = {
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(data)
    };
    return fetch(`${config}/dashboard/users`, requestOptions).then(handleResponse);
}
function GetGroupTable(data) {
    const requestOptions = {
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(data)
    };
    return fetch(`${config}/dashboard/groups`, requestOptions).then(handleResponse);
}
function GetRidesTable(data) {
    const requestOptions = {
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(data)
    };
    return fetch(`${config}/dashboard/rides`, requestOptions).then(handleResponse);
}
function EditUser(user) {
    const requestOptions = {
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(user)
    };
    return fetch(`${config}/dashboard/users/edit`, requestOptions).then(handleResponse);
}
function EditGroup(group) {
    const requestOptions = {
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(group)
    };
    return fetch(`${config}/dashboard/groups/edit`, requestOptions).then(handleResponse);
}
function DeleteUser(user_id) {
    const requestOptions = {
        method: 'GET',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
    };
    return fetch(`${config}/dashboard/users/delete/${user_id}`, requestOptions).then(handleResponse);
}
function ViewUser(user_id) {
    const requestOptions = {
        method: 'GET',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
    };
    return fetch(`${config}/dashboard/user/groups/${user_id}`, requestOptions).then(handleResponse);
}
function ViewGroup(group_id) {
    const requestOptions = {
        method: 'GET',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
    };
    return fetch(`${config}/dashboard/groups/group/users/${group_id}`, requestOptions).then(handleResponse);
}
function DeleteGroup(id) {
    const requestOptions = {
        method: 'GET',
        headers: {...authHeader(), 'Content-Type': 'application/json'},

    };
    return fetch(`${config}/dashboard/groups/delete/${id}`, requestOptions).then(handleResponse);
}
function DeleteRide(ride_del) {
    const requestOptions = {
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(ride_del)
    };
    return fetch(`${config}/dashboard/rides/change-status`, requestOptions).then(handleResponse);
}
function MessageGroup(groupMessage) {
    const requestOptions = {
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(groupMessage)
    };
    return fetch(`${config}/dashboard/groups/notify`, requestOptions).then(handleResponse);
}

function GetGraph(getData) {
    const requestOptions = {
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(getData)
    };
    return fetch(`${config}/dashboard/stat/graph-stat`, requestOptions).then(handleResponse);
}
function GetStatisic() {
    const requestOptions = {
        method: 'GET',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
    };
    return fetch(`${config}/dashboard/stat/count-stat`, requestOptions).then(handleResponse);
}
function GetSettings() {
    const requestOptions = {
        method: 'GET',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
    };
    return fetch(`${config}/dashboard/get-settings/any`, requestOptions).then(handleResponse);
}
function SetSettings(settings) {
    const requestOptions = {
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(settings)
    };
    return fetch(`${config}/dashboard/set-settings`, requestOptions).then(handleResponse);
}
function handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 400 || 300 || 302 || 422 || 403 || 401 ) {
                const error = data;
                return Promise.reject(error);
            }
            if (response.status === 406) {
                localStorage.removeItem('user_token');
                localStorage.setItem('LoginStatus', false);
                window.location.reload();
            }


        }

        return data;
    });
}