/**
 * Created by user on 27.07.2018.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Avatar from '../../img/avatar.svg';
import { userActions } from '../../actions/index';
class ViewUser extends Component{
    constructor(props){
        super(props)
        const UserContent =this.props.modals.data;
        this.state = {
           user_id: UserContent._id,
           user_name: UserContent.name

        }


    }
    componentDidMount(){
        const { dispatch } = this.props;
        dispatch(userActions.ViewUser(this.state.user_id));
    }

    render() {
        const {ViewList} =this.props
        return (
            <div>
                <div className="modal_head">Groups: {this.state.user_name} user</div>

                {ViewList && ViewList.List && ViewList.List.groups && ViewList.List.groups.length ?
                    <ul className="list_data">
                        {ViewList.List.groups.map((item, index)=>
                        <li key={index}><img src={item.groupType?'http://www.ifixgroup.com:4050/icons/' + item.groupType.icon : Avatar} width="20" height="20" alt=""/>{item.name}</li>
                    )}
                        </ul>
                    :<div className="dont_list">This user does not have a group.</div>

                }


            </div>
        )
    }
}
function mapStateToProps(state) {
    const {modals, ViewList} = state
    return {
        modals,
        ViewList
    };
}
export default connect(mapStateToProps)(ViewUser);
