/**
 * Created by user on 27.07.2018.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../actions/index';
import { alertActions } from '../../actions/index';
import { userActions } from '../../actions/index';
class EditUser extends Component{
    constructor(props){
        super(props)
        const EditContent =this.props.modals.data;
        this.state = {
            user:{
                picName: EditContent.picName,
                created_at:EditContent.created_at,
                name:EditContent.name,
                lastName:EditContent.lastName,
                email:EditContent.email,
                user_id:EditContent._id,
                password:'',
                admin: EditContent.admin
            },
            type: EditContent.type

        }
        this.handleChangeInput = this.handleChangeInput.bind(this)
        this.removeImg = this.removeImg.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.close_modal = this.close_modal.bind(this)
    }
    handleChangeInput(e) {
        const { name } = e.target;
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
    }
    removeImg() {
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                picName: ''
            }

        })
    }
    close_modal() {
        const { dispatch } = this.props;
        dispatch(modalActions.close_modal())
        dispatch(alertActions.clear())
    }
    handleSubmit(event) {
        event.preventDefault();
        const { user } = this.state;
        const { dispatch } = this.props;
        dispatch(userActions.EditUser(user));
    }
    render() {
        const { user } = this.state;
        const { message } = this.props.alert;
        return (
            <div>
                <div className="modal_head">Edit property</div>
                <form onSubmit={this.handleSubmit} className="m_form d-flex flex-column">
                        {user.picName?
                            <div className="img_edit">
                                <img src={'http://www.ifixgroup.com:4050/pictures/'+ user.picName } alt=""/>
                                <div className="remove_pic fa" onClick={this.removeImg}>Remove image</div>
                            </div>:
                            ''}

                    <label className={message&& message.created_at &&'error_lab'}>
                        <span className="label_txt">Created at</span>
                        <input type="text" className="form-control" name="created_at" value={user.created_at?user.created_at:''} onChange={this.handleChangeInput}/>
                        {message&& message.created_at && <span className="error_mes">{message.created_at[0]}</span> }
                    </label>
                    <label className={message&& message.name &&'error_lab'}>
                        <span className="label_txt">Name</span>
                        <input type="text" className="form-control" name="name" value={user.name?user.name:''} onChange={this.handleChangeInput}/>
                        {message&& message.name && <span className="error_mes">{message.name[0]}</span> }
                    </label>
                    <label className={message&& message.lastName &&'error_lab'}>
                        <span className="label_txt">Last Name</span>
                        <input type="text" className="form-control" name="lastName" value={user.lastName?user.lastName:''} onChange={this.handleChangeInput}/>
                        {message&& message.lastName && <span className="error_mes">{message.lastName[0]}</span> }
                    </label>
                    <label className={message&& message.email &&'error_lab'}>
                        <span className="label_txt">Email</span>
                        <input type="text" className="form-control" name="email" value={user.email?user.email:''} onChange={this.handleChangeInput}/>
                        {message&& message.email && <span className="error_mes">{message.email[0]}</span> }
                    </label>
                    <label className={message&& message.password &&'error_lab'}>
                        <span className="label_txt">New Password</span>
                        <input type="text" className="form-control" name="password" value={user.password?user.password:''} onChange={this.handleChangeInput}/>
                        {message&& message.password && <span className="error_mes">{message.password[0]}</span> }
                    </label>
                    {this.state.type === "merchant" &&
                        <label className={message&& message.admin &&'error_lab'}>
                            <span className="label_txt">Is Admin</span>
                            <input type="checkbox" className="form-control checkbox" name="admin" checked={user.admin?user.admin:''} onChange={this.handleChangeInput}/>
                            {message&& message.admin && <span className="error_mes">{message.admin[0]}</span> }
                        </label>
                    }



                    <div className="but_cont">
                        <button type="submit" className="btn btn-primary block  m-b">Save</button>
                        <button type="button" className="btn btn-grey block  m-b"  onClick={this.close_modal}>Cancel</button>
                    </div>
                </form>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const {modals, alert} = state
    return {
        modals,
        alert
    };
}

export default connect(mapStateToProps)(EditUser);
