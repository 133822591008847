import { userService } from '../services';
import { alertActions } from './';
import { modalActions } from './';

export const getInfo = {
    GetUsersTable,
    GetGroupTable,
    GetRidesTable
};
function GetUsersTable(data) {
    return dispatch => {
        dispatch(request());
        userService.GetUsersTable(data)
            .then(
                UsersTable => {
                    dispatch(success(UsersTable));
                    dispatch(success1());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(UsersTable) { return { type: 'GET_USER_TABLE', UsersTable } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function GetGroupTable(data) {
    return dispatch => {
        dispatch(request());
        userService.GetGroupTable(data)
            .then(
                GroupTable => {
                    dispatch(success(GroupTable));
                    dispatch(success1());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(GroupTable) { return { type: 'GET_GROUP_TABLE', GroupTable } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function GetRidesTable(data) {
    return dispatch => {
        dispatch(request());
        userService.GetRidesTable(data)
            .then(
                RidesTable => {
                    dispatch(success(RidesTable));
                    dispatch(success1());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(RidesTable) { return { type: 'GET_RIDES_TABLE', RidesTable } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}