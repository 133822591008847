
const initialState = { loading: false } ;

export function loading(state = initialState, action) {
    switch (action.type) {
        case 'REQUEST':
            return {
                loading: true,
            };
        case 'SUCCESS':
            return {
                loading: false,
            };
        case 'FAILURE':
            return {
                loading: true,
            };
        default:
            return state
    }
}
