/**
 * Created by user on 21.11.2018.
 */
export function UsersTable(state = {}, action) {
    switch (action.type) {
        case 'GET_USER_TABLE':
            return {
                UsersTable: action.UsersTable.users,
                Count: action.UsersTable.count
            };
        case 'SUCCESS_EDIT_USER':
            return{
                ...state,
                UsersTable: state.UsersTable.map(user=>
                user._id === action.user.user._id
                ? action.user.user
                : user
                )

            }
        case 'SUCCESS_DELETE_USER':
            return{
                ...state,
                UsersTable: state.UsersTable.map(user=>
                    user._id === action.user.user._id
                        ? action.user.user
                        : user
                )

            }
        default:
            return state
    }
}
export function GroupTable(state = {}, action) {
    switch (action.type) {
        case 'GET_GROUP_TABLE':
            return {
                GroupTable: action.GroupTable.groups,
                Count: action.GroupTable.count
            };
        case 'SUCCESS_EDIT_GROUP':
            return{
                ...state,
                GroupTable: state.GroupTable.map(user=>
                    user._id === action.group.group._id
                        ? action.group.group
                        : user
                )
                // group1:action.group

            }
        case 'SUCCESS_DELETE_GROUP':
            return{
                ...state,
                GroupTable: state.GroupTable.map(group=>
                    group._id === action.group.group._id
                        ? action.group.group
                        : group
                )

            }
        default:
            return state
    }
}
export function RidesTable(state = {}, action) {
    switch (action.type) {
        case 'GET_RIDES_TABLE':
            return {
                RidesTable: action.RidesTable.rides,
                Count: action.RidesTable.count
            };
        case 'SUCCESS_DELETE_RIDE':
            return{
                ...state,
                RidesTable: state.RidesTable.map(ride=>
                    ride._id === action.ride._id
                        ? action.ride
                        : ride
                )

            }
        default:
            return state
    }
}
export function ViewList(state = {}, action) {
    switch (action.type) {
        case 'VIEW_USER':
            return {
                List: action.Groups,
            }
        case 'VIEW_GROUP':
            return {
                List: action.Users,
            }
        default:
            return state
    }
}

export function GraphData(state = {GraphData:{}}, action) {
    switch (action.type) {
        case 'VIEW_GRAPH':
            Object.keys(action.getData).map(function(key, index) {
                return state.GraphData[key]=action.getData[key]

            })
            return{
                // GraphData: Object.keys(action.getData).map(function(key, index) {
                //     state.GraphData[key]=action.getData[key]
                //
                // })
                // GraphData:action.getData,
                GraphData:state.GraphData
            }
        default:
            return state
    }
}
export function GetStatisic(state = {}, action) {
    switch (action.type) {
        case 'STATISTICS':
            return {
                statistics: action.statistics,
            }
        default:
            return state
    }
}
export function GetSettings(state = {}, action) {
    switch (action.type) {
        case 'GET_SETTINGS':
            return {
                getSettings: action.getSettings,
            }
        default:
            return state
    }
}