import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link} from "react-router-dom";
import logo from '../img/logo.png';
import $ from "jquery";
import { userActions} from '../actions';
import  {Popup}  from './Popups';
import { history } from '../helpers';
import LoadingBar from 'react-redux-loading-bar'
class DashboardFix extends Component {

    constructor(props) {
        super(props);
        this.NavLinkActive = this.NavLinkActive.bind(this);
        history.listen((location, action) => {
            this.NavLinkActive()
        });
        this.LogOut = this.LogOut.bind(this)
    }
    componentDidMount() {
        this.NavLinkActive()
        $('.nav_button').click(function(){
            if(!$(this).hasClass('act_but')){
                $(this).addClass('act_but');
                $('body').addClass('active_menu');
            }
            else{
                $(this).removeClass('act_but');
                $('body').removeClass('active_menu');
            }

        })
        if($( window ).width() <= 768){

            $('.navlink').click(function(){
                $('.nav_button').removeClass('act_but');
                $('body').removeClass('active_menu');

            })
        }

    }
    NavLinkActive (){
        $('.navlink').removeClass('active');
        $('body').find('.navlink').each(function () {
            if($(this).attr('href') === history.location.pathname){
                $(this).addClass('active')
            }
        })
    }
    LogOut(){
        const { dispatch } = this.props;
        return dispatch(userActions.logout());
    }

    render() {
        return (
            <header id="header">
                <Popup />
                <LoadingBar className="loading" />
                <div className="container1">

                    <div className="left">


                        <div className="nav_cont">
                            <img src={logo} className="logo" alt="logo"/>
                            <nav>
                                <ul>

                                    <li>
                                        <Link to="/dashboard"  className="navlink"> <i className="fa fa-th-large"></i>dashboard</Link>
                                    </li>
                                    <li>
                                        <Link to="/drivers"  className="navlink"><i className="fa fa-id-card-o" ></i>Drivers</Link>
                                    </li>
									 <li>
                                        <Link to="/users"  className="navlink"><i className="fa fa-users" ></i>Merchants</Link>
                                    </li>
                                    <li>
                                        <Link to="/groups"  className="navlink"><i className="fa fa-object-group" ></i>merchant groups</Link>
                                    </li>
                                    <li>
                                        <Link to="/rides"  className="navlink"><i className="fa fa-cutlery" ></i>orders</Link>
                                    </li>

                                </ul>
                            </nav>
                        </div>
                        <div className="nav_button">
                            <div className="icon-bar bar1"></div>
                            <div className="icon-bar bar2"></div>
                            <div className="icon-bar bar3"></div>
                        </div>
                    </div>
                    <div></div>
                    <div className="right">
                        <div className="logout" onClick={this.LogOut}><i className="fa fa-sign-out"></i>Log out</div>
                    </div>
                </div>

            </header>
        )
    }
}
function mapStateToProps(state) {
    return {};
}


export default connect(mapStateToProps)(DashboardFix);
