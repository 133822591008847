/**
 * Created by user on 27.07.2018.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
class Fail_popup extends Component{
    render() {

        return (
            <form  className="m_form  d-flex flex-column">

                <div  className="m_form  d-flex flex-column">
                    <span className="fa fa-warning"  ></span>
                    <div className="head_mess">{this.props.modals.data}</div>


                </div>
            </form>
        )
    }
}
function mapStateToProps(state) {
    const {modals} = state
    return {
        modals
    };
}
export default connect(mapStateToProps)(Fail_popup);
