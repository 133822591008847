
import React, { Component } from "react";
import { connect } from 'react-redux';
import { modalActions } from '../../actions/index';
import {  Modal, ModalBody } from 'reactstrap';
import { alertActions } from '../../actions/index';
import  Empty  from './empty.js';

import  Success  from './success.js';

import  Fail_popup  from './fail_poup.js';
import  EditUser  from './edit_user.js';
import  DeleteUser  from './delete_user.js';
import  EditGroup  from './edit_group.js';
import  DeleteGroup  from './delete_group.js';
import  ViewUser  from './view_user.js';
import  ViewGroup  from './view_group.js';
import  DeleteRide  from './delete_ride.js';
import  MessageGroup  from './message_group.js';

class Popup extends Component {
    close_modal() {
        const { dispatch } = this.props;
        dispatch(modalActions.close_modal())
        dispatch(alertActions.clear())
    }
    render() {
        const components = {
            'Empty': Empty,
            'Success': Success,
            'Fail_popup':Fail_popup,
            'EditUser':EditUser,
            'DeleteUser':DeleteUser,
            'EditGroup':EditGroup,
            'DeleteGroup':DeleteGroup,
            'ViewUser':ViewUser,
            'ViewGroup':ViewGroup,
            'DeleteRide':DeleteRide,
            'MessageGroup':MessageGroup,

        };
       let FuncMod =  components[this.props.modals.modal_name || 'Empty' ];
        return (

            <div>
                <Modal isOpen={this.props.modals.open_modal} toggle={()=>this.close_modal()} className='modal-dialog-centered'>
                    <button type="button" className="close_mod" onClick={()=>this.close_modal()}><i className="fa fa-times" aria-hidden="true"></i></button>
                    <ModalBody >
                          <FuncMod />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        modals: state.modals
    };
}

const connectedPopup = connect(mapStateToProps)(Popup);
export { connectedPopup as Popup};


