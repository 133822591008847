import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer} from 'recharts'
import { userActions } from '../../actions/index';


import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';


import moment from 'moment';


// http://recharts.org/en-US/examples/CardinalAreaChart
class Dashboard extends Component {

    constructor(props) {
        super(props);
        const startDate1 = new Date();
        const endDate1 = new Date();
        startDate1.setDate(startDate1.getDate() - 30 );
        const startDate = moment(startDate1).format('MM/DD/YYYY');
        const endDate = moment(endDate1).format('MM/DD/YYYY');
        this.state={
            dateLimit:{
                days:30
            },
            settings:{
                perGallon:'',
            },

            date:{
                emission:{
                    startDate:startDate,
                    endDate:endDate
                },
                saved:{
                    startDate:startDate,
                    endDate:endDate
                },
                cars:{
                    startDate:startDate,
                    endDate:endDate
                },
                gas:{
                    startDate:startDate,
                    endDate:endDate
                },
            },
            getData:{
                startDate:startDate,
                endDate:endDate,
                type:'all'
            }



        }
        this.handleEvent = this.handleEvent.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeInput = this.handleChangeInput.bind(this)

    }
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(userActions.GetGraph(this.state.getData))
        dispatch(userActions.GetStatisic())
        dispatch(userActions.GetSettings())
    }
    componentWillReceiveProps(nextProps){
        if(nextProps.getSettings && nextProps.getSettings.settings.perGallon ){
            this.setState({
                settings:{
                    perGallon: nextProps.getSettings.settings.perGallon,
                }

            })
        }


    }
    handleEvent(event, picker) {
        const { dispatch } = this.props;
        const date_name = picker.element[0].children[0].id;
        const { date } = this.state;
        this.setState({
            getData: {
                    startDate: moment(picker.startDate).format('MM/DD/YYYY'),
                    endDate: moment(picker.endDate).format('MM/DD/YYYY'),
                    type: date_name

            }
        }, function () {
            dispatch(userActions.GetGraph(this.state.getData))
        });
        this.setState({
            date: {
                ...date,
                [date_name]: {
                    startDate: moment(picker.startDate).format('MM/DD/YYYY'),
                    endDate: moment(picker.endDate).format('MM/DD/YYYY')
                }
            }
        });

    }
    handleChangeInput(e) {
        const { name, value } = e.target;
        if (/^\d{0,6}(\.\d{0,4}){0,1}$/.test(value)) {
            this.setState({
                settings:{
                    [name]: value
                }

            });
        }

    }
    handleSubmit(event) {
        event.preventDefault();
        const { settings } = this.state;
        const { dispatch } = this.props;
        dispatch(userActions.SetSettings(settings));
    }

    render() {
        const { date } = this.state;
        const {GraphData, statistics} =this.props
        return (

            <div className="main_container">
                <div className="row">
                    <div className="cont2">
                        <div className="widget color1">
                            <h2>Statistics</h2>
                            <div className="txt_widget">
                                <div className="row">
                                    <div className="txt_lab width_190">Drivers:</div>
                                    <div >{statistics && statistics.driversCount?statistics.driversCount:0}</div>

                                </div>
                            </div>
							<div className="txt_widget">
                                <div className="row">
                                    <div className="txt_lab width_190">Merchants:</div>
                                    <div >{statistics && statistics.merchantsCount?statistics.merchantsCount:0}</div>

                                </div>
                            </div>
                            <div className="txt_widget">
                                <div className="row">
                                    <div className="txt_lab width_190">Merchant Groups:</div>
                                    <div >{statistics && statistics.groupsCount?statistics.groupsCount:0}</div>

                                </div>
                            </div>
                            <div className="txt_widget">
                                <div className="row">
                                    <div className="txt_lab width_190">Orders:</div>
                                    <div >{statistics && statistics.ridesCount?statistics.ridesCount:0}</div>

                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const {GraphData} = state.GraphData
    const {statistics} = state.GetStatisic
    const {getSettings} = state.GetSettings
    return {
        GraphData,
        statistics,
        getSettings
    };
}

const connectedDashboard = connect(mapStateToProps)(Dashboard);
export { connectedDashboard as Dashboard };