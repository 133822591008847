/**
 * Created by user on 27.07.2018.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../actions/index';
import { alertActions } from '../../actions/index';
import { userActions } from '../../actions/index';
class EditGroup extends Component{
    constructor(props){
        super(props)
        const EditContent = this.props.modals.data;
        this.state = {
            types:EditContent.groupTypes,
            group:{
                groupType: EditContent.groupType._id,
                name:EditContent.name,
                about:EditContent.about,
                private:EditContent.private?'1':'0',
                code:EditContent.code?EditContent.code:'',
                group_id:EditContent._id
            }

        }


        this.handleChangeInput = this.handleChangeInput.bind(this)
        this.handleChangeSelect = this.handleChangeSelect.bind(this)
        this.handleChangeSelect1 = this.handleChangeSelect1.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.close_modal = this.close_modal.bind(this)
    }
    handleChangeInput(e) {
        const { name, value } = e.target;
        const { group } = this.state;
        this.setState({
            group: {
                ...group,
                [name]: value
            }
        });
    }
    handleChangeSelect(event) {
        const { group } = this.state;
        this.setState({
            group: {
                ...group,
                groupType: event.target.value
            }
        })
    }
    handleChangeSelect1(event) {
        const { group } = this.state;
        this.setState({
            group: {
                ...group,
                private: event.target.value
            }
        })


    }
    close_modal() {
        const { dispatch } = this.props;
        dispatch(modalActions.close_modal())
        dispatch(alertActions.clear())
    }
    handleSubmit(event) {
        event.preventDefault();
        const { group } = this.state;
        const { dispatch } = this.props;
        dispatch(userActions.EditGroup(group));
    }
    render() {
        const { group } = this.state;
        const { message } = this.props.alert;
        return (
            <div>
                <div className="modal_head">Edit property</div>
                <form onSubmit={this.handleSubmit} className="m_form d-flex flex-column">
                    <label>
                        <span className="label_txt">Type</span>
                        <select value={this.state.group.groupType}  onChange={this.handleChangeSelect}>
                            {this.state.types.map(
                                (item, index)=>
                                    <option key={index} value={item._id} >{item.name}</option>
                            )}
                        </select>
                    </label>
                    <label className={message&& message.name &&'error_lab'}>
                        <span className="label_txt">Name</span>
                        <input type="text" className="form-control" name="name" value={group.name?group.name:''} onChange={this.handleChangeInput}/>
                        {message&& message.name && <span className="error_mes">{message.name[0]}</span> }
                    </label>
                    <label className={message&& message.about &&'error_lab'}>
                        <span className="label_txt">About</span>
                        <input type="text" className="form-control" name="about" value={group.about?group.about:''} onChange={this.handleChangeInput}/>
                        {message&& message.about && <span className="error_mes">{message.about[0]}</span> }
                    </label>
                    <label >
                        <span className="label_txt">Status</span>
                        <select value={this.state.group.private}  onChange={this.handleChangeSelect1}>
                            <option value='1'>Private</option>
                            <option value='0'>Public</option>
                        </select>
                    </label>
                    { this.state.group.private ==='1'?
                    <label className={message&& message.code &&'error_lab'}>
                        <span className="label_txt">Code</span>
                        <input type="text" className="form-control" name="code" value={group.code?group.code:''} onChange={this.handleChangeInput}/>
                        {message&& message.code && <span className="error_mes">{message.code[0]}</span> }
                    </label>
                        :""
                    }


                    <div className="but_cont">
                        <button type="submit" className="btn btn-primary block  m-b">Save</button>
                        <button type="button" className="btn btn-grey block  m-b"  onClick={this.close_modal}>Cancel</button>
                    </div>
                </form>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const {modals, alert} = state
    return {
        modals,
        alert
    };
}

export default connect(mapStateToProps)(EditGroup);
