/**
 * Created by user on 27.07.2018.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../actions/index';
import { alertActions } from '../../actions/index';
import { userActions } from '../../actions/index';
class MessageGroup extends Component{
    constructor(props){
        super(props)
        const Content = this.props.modals.data;
        this.state = {
            groupName:Content.name,
            groupMessage:{
                group_id: Content._id,
                message: ''
            }

        }


        this.handleChangeInput = this.handleChangeInput.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.close_modal = this.close_modal.bind(this)
    }
    handleChangeInput(e) {
        const { name, value } = e.target;
        const { groupMessage } = this.state;
        this.setState({
            groupMessage: {
                ...groupMessage,
                [name]: value
            }
        });
    }

    close_modal() {
        const { dispatch } = this.props;
        dispatch(modalActions.close_modal());
        dispatch(alertActions.clear());
    }
    handleSubmit(event) {
        event.preventDefault();
        const { groupMessage } = this.state;
        const { dispatch } = this.props;
        dispatch(userActions.MessageGroup(groupMessage));
    }
    render() {
        const { groupMessage } = this.state;
        const { message } = this.props.alert;
        return (
            <div>
                <div className="modal_head">Send message to {this.state.groupName} group</div>
                <form onSubmit={this.handleSubmit} className="m_form d-flex flex-column">
                    <label className={message&& message.message &&'error_lab'}>
                        <span className="label_txt">Messange</span>
                        <textarea type="text" className="form-control" name="message" value={groupMessage.messange} onChange={this.handleChangeInput}/>
                        {message&& message.message && <span className="error_mes">{message.message[0]}</span> }
                    </label>



                    <div className="but_cont">
                        <button type="submit" className="btn btn-primary block  m-b">Send</button>
                        <button type="button" className="btn btn-grey block  m-b"  onClick={this.close_modal}>Cancel</button>
                    </div>
                </form>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const {modals, alert} = state
    return {
        modals,
        alert
    };
}

export default connect(mapStateToProps)(MessageGroup);
