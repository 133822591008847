import React, { Component } from 'react';
import { connect } from 'react-redux';
import {  modalActions } from '../../actions/index';
import {getInfo} from '../../actions';
import $ from "jquery";
import Pagination from "react-js-pagination";
import Avatar from '../../img/avatar.svg';
import { Table } from 'reactstrap';

class Drivers extends Component {

    constructor(props) {
        super(props);
        this.state={
            tableData: {
                searchValue: '',
                sortValue:'',
                sortPosition: '',
                activePage: 1,
                type: 'driver'
            },
            pageRangeDisplayed: 5

        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.SortClass = this.SortClass.bind(this);
        this.onBtnClick = this.onBtnClick.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this)

    }
    handleChange(event) {
        $('table tr').removeClass('edited')
        const { dispatch } = this.props;
        const { tableData } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                searchValue: event.target.value,
                activePage: 1,
            }
        }, function () {
            const _this=this
            setTimeout(function () {
                dispatch(getInfo.GetUsersTable(_this.state.tableData))
            }, 500)
        });
    }
    handlePageChange(pageNumber) {
        $('table tr').removeClass('edited')
        const { dispatch } = this.props;
        const { tableData } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                activePage: pageNumber
            }
        }, function () {
            dispatch(getInfo.GetUsersTable(this.state.tableData))
        });

    }
    SortClass(event){
        $('.clickable').removeClass('active_up');
        $('.clickable').removeClass('active_down');

    };

    handleSort(event){
        $('table tr').removeClass('edited')
        let element = event.target.classList;
        if(!element.contains('active_up') && !element.contains('active_down')){
            this.SortClass();
            element.add('active_up');

        }
        else if(element.contains('active_up') && !element.contains('active_down')){
            element.remove('active_up')
            element.add('active_down')
        }
        else if(!element.contains('active_up') && element.contains('active_down')){
            element.remove('active_down')
            element.add('active_up')
        }
        const { dispatch } = this.props;
        const x = this.state.tableData.sortPosition
        const { tableData } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                activePage: 1,
                sortValue: event.currentTarget.getAttribute('data-val'),
                sortPosition: x ===''|| x ==='down'?'up':'down'
            }
        }, function () {
            dispatch(getInfo.GetUsersTable(this.state.tableData))
        });
    // console.log(event.currentTarget.textContent)
    }
    componentDidMount() {
        if($(window).width()<=768){
            this.setState({
                pageRangeDisplayed:3
            })
        }
        const { dispatch } = this.props;
        dispatch(getInfo.GetUsersTable(this.state.tableData))
    }

// && tableData.sortValue == this.value
    onBtnClick(event, data){
        const { dispatch } = this.props;
        // $(this).parent().parent()
        $('table tr').removeClass('edited')
        $(event.target).parent().parent().addClass('edited')
        return dispatch(modalActions.open_modal(event.target.getAttribute('data_modal'), data))
    }
    //
    handleChangeSelect(event) {
        const { tableData } = this.state;
        const { dispatch } = this.props;
        this.setState({
            tableData: {
                ...tableData,
                activePage: 1,
                sortValue: event.target.value,
                sortPosition: 'up'
            }
        }, function () {
            dispatch(getInfo.GetUsersTable(this.state.tableData))
        });
    }
    render() {
        const {UsersTable, Count} =this.props
        let role = localStorage.getItem('role');
        return (
            <div className="main_container">
                <label className="search">
                    <input type="text" value={this.state.tableData.searchValue} onChange={this.handleChange} placeholder="Search" className="form-control"/>
                </label>
                <select value={this.state.tableData.sortValue} onChange={this.handleChangeSelect} className="selectBox">
                    <option value="">Change sort by</option>
                    <option value="created_at">Created at</option>
                    <option value="name">Name</option>
                    <option value="lastName">Last Name</option>
                    <option value="email">Email</option>
                    <option value="deleted_at">Deleted at</option>
                    {/*<option value="rate">Rating</option>*/}
                </select>
                <Table responsive striped>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th >Picture</th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="created_at">Created at</th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="name">Name</th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="lastName">Last Name</th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="email">Email</th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="deleted_at">Deleted at</th>
                        {/*<th className="clickable" onClick={this.handleSort}  data-val="rate">Rating</th>*/}
                        <th >Rating</th>
                        <th >Action</th>
                    </tr>
                    </thead>
                        <tbody>{UsersTable ?  UsersTable.map( (item, index)=>

                           <tr key={index}>
                                <td style={{width:'3%'}}  nowrap='true'><div className='table_head_txt'>Number:</div> {(this.state.tableData.activePage - 1)*10 + 1 + index } </td>
                                <td style={{width:'7%'}}  nowrap='true' className="pict"><div className='table_head_txt'>Picture:</div><img src={item.picName?'http://relaypigeon.com:5050/pictures/' + item.picName : Avatar} alt=""/></td>
                                <td style={{width:'10%'}} nowrap='true'><div className='table_head_txt'>Created at:</div>{item.created_at ? item.created_at:''}</td>
                                <td style={{width:'10%', maxWidth: '350px', overflow:'hidden'}} nowrap='true'><div className='table_head_txt'>Name:</div>{item.name?item.name:''}</td>
                                <td style={{width:'20%', maxWidth: '450px', overflow:'hidden'}} nowrap='true'><div className='table_head_txt'>Last Name:</div>{item.lastName?item.lastName:''}</td>
                                <td style={{width:'20%'}} nowrap='true'><div className='table_head_txt'>Email:</div>{item.email?item.email:''}</td>
                                <td style={{width:'10%'}} nowrap='true'><div className='table_head_txt'>Deleted at:</div>{item.deleted_at?item.deleted_at:'Active'}</td>
                                <td style={{width:'10%'}} nowrap='true'><div className='table_head_txt'>Rating:</div>{item.rate?item.rate:'0'}</td>

                                <td style={{width:'10%'}} nowrap='true' className="actions">
                                    <div className='table_head_txt'>Action:</div>
                                    {role && role !== 'business' && <span className="edit fa"  onClick={(e) => { this.onBtnClick(e, {...item, type: 'driver'} ) }}  data_modal="EditUser"></span>}
                                    <span className="fa-eye fa"  onClick={(e) => { this.onBtnClick(e, item ) }}  data_modal="ViewUser"></span>
                                    {role && role !== 'business' && !item.deleted_at && <span className="delete fa"  onClick={(e) => { this.onBtnClick(e, item ) }}  data_modal="DeleteUser" ></span>}
                                </td>
                            </tr>
                        )
                            :false
                        }</tbody>

                </Table>
                <div className="pagin_cont">

                    {Count && Count>10?
                        <Pagination
                            activePage={this.state.tableData.activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={Count ? Count:''}
                            pageRangeDisplayed={this.state.pageRangeDisplayed}
                            firstPageText={<i className='f_link1'>First</i>}
                            lastPageText={<i className='b_link1'>Last</i>}
                            prevPageText={<i className='f_link2'/>}
                            nextPageText={<i className='b_link2'/>}
                            onChange={this.handlePageChange}
                        />
                        :''}
                </div>

            </div>
        )
    }
}
function mapStateToProps(state) {
     const {UsersTable, Count} = state.UsersTable
    return {
        UsersTable,
        Count
    };
}

const connectedUsers = connect(mapStateToProps)(Drivers);
export { connectedUsers as Drivers };