import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { alert } from './alert.reducer';
import { loading } from './request';
import { modals } from './modal.reducer';
import { UsersTable } from './getInfo.reducer';
import { GroupTable } from './getInfo.reducer';
import { ViewList } from './getInfo.reducer';
import { RidesTable } from './getInfo.reducer';
import { GraphData } from './getInfo.reducer';
import { GetStatisic } from './getInfo.reducer';
import { GetSettings } from './getInfo.reducer';
import { loadingBarReducer } from 'react-redux-loading-bar'

const rootReducer = combineReducers({
      authentication,
      alert,
      loading,
      modals,
      UsersTable,
      GroupTable,
      ViewList,
      RidesTable,
      GraphData,
      GetStatisic,
      GetSettings,
      loadingBar: loadingBarReducer,
});

export default rootReducer;